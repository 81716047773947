@use "sass:math";

// Fonts
// ----------------------------------------------------------------------------
$font-family-primary-sans-serif: 'Roboto', sans-serif;
$font-family-secondary-sans-serif: "proxima-nova", 'helvetica neue', helvetica, arial, sans-serif;

$base-font-size:        20px;
$base-line-height:      24px;

$font-size-xxsmall:     11px;

$line-height-headline:  1.45;

$font-weight-light:     300;
$font-weight-normal:    400;
$font-weight-medium:    500;
$font-weight-bold:      700;


// Spacing
// ----------------------------------------------------------------------------
$base-spacing-unit:     1.5rem; // 24px

$base-padding:          $base-spacing-unit;

$spacing-grid:          $base-spacing-unit;

$spacing:               $base-spacing-unit;
// Smaller
$spacing-half:          math.div($spacing, 2);
// Larger
$spacing-double:        ($spacing * 2);

// Others
// ----------------------------------------------------------------------------
$base-transition:       all .1s ease-in;

