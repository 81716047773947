.notification-banner {
    .rich-text {
        a {
            color: white !important;  // be nice to use the color() function...
            text-decoration: underline;
            cursor: pointer;
            &:focus {
                @include a-focus-state;
            }
        }
    }
}
