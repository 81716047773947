

.u-color-penny-blue {
    color: $color-penny-blue;
}


.u-color-penny-red {
    color: $color-penny-red;
}


.u-color-penny-black {
    color: $color-penny-black;
}