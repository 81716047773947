.mobile-menu {
    

    @include fixed(0,30px,0,0,110);
    

    transform: translateX(-100%);
    transition: all 0.5s ease;
    animation-duration: .3s;
    animation-fill-mode: both;
    will-change: transform,opacity;

    height: 100%;
    background-color: #C9347D;
    padding: 4rem 1.5rem;
    color: white;
    overflow: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    // &:after {
    //     content: "";
    //     width: 22px;
    //     height: 256px;
    //     background:red url(/static/images/mobile-nav-side.png) no-repeat -30px -50px fixed;
    //     top: 0;
    //     right: -25px;
    //     position: absolute;
    //     display: inline-block;
    // }

         
    a {
        color: white;
    }

    .block-list a {
        text-decoration: underline;
    }

}

.mobile-menu nav {
    margin-top: 30px;
}

.mobile-menu__item {
    padding: 0;

    a {
        padding: .5rem 0;
        display: block;
        margin: 0;
        font-size: 22px;
    }

} 


.mobile-menu__social-nav {
    a {
        display: inline-block;
        margin: 0 1rem;
    }
}

body.mobile-menu--visible {
    overflow: hidden;
    position: fixed;

    @include medium {
        overflow: scroll;
    }

    .mobile-menu {
        animation-name: slideIn;
    }
}

body.mobile-menu--hidden {
    .mobile-menu {
        animation-name: slideOut;
    }
}

.mobile-menu-toggle {
    position: absolute;
    left: 27px;
    top: 15px;
    z-index: 9999;
}

.test {
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
}

