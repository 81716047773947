// --------------------------------------------------
// Buttons
// -------------------------------------------------



.btn {
    display: inline-block;              // [1]
    vertical-align: middle;             // [2]
    font: inherit;                      // [3]
    text-align: center;                 // [4]
    margin: 0;                          // [4]
    cursor: pointer;                    // [5]
    background-color: $color-black-a10;
    color: #333;
    transition: $base-transition;
    text-decoration: none;

    white-space: nowrap;                // [7]
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 20px;
    line-height: 2;
    padding: 12px 20px;
    border: 1px solid transparent;
    height: 72px;
    border-radius: 4px;

    @include small {
        max-width: 100%;
    }

    &:hover {
        text-decoration: none;
    }

    &:focus {
        @include btn-focus-state;
        background-color: #e28a06 !important; // important to keep the non-focus styling
        padding: 12px 20px;
        border-radius: unset;
    }

    &[disabled],
    &.is-disabled {
        pointer-events: none;
        cursor: default;
        opacity: .5 !important;
    }

}


// Size modifiers
// ----------------------------------------------------------------------------


.btn--tiny {
    font-size: 18px;
    line-height: 14px;
    padding: 10px 18px;
}

.btn--small {
    font-size: 18px;
    line-height: 14px;
    padding: 10px 18px;
}

.btn--large {
    font-size: 25px;
    line-height: 1.25;
    padding: 15px 25px;

}


.btn--yellow {
    background-color: $color-yellow;
    // color: $color-yellow;
}
// Colors modifiers
// ----------------------------------------------------------------------------


.btn--pink {
    background-color: $color-pink;
}


.btn--hollow {
    background-color: transparent;
    color: $color-yellow;
    border-color: $color-yellow;
}

.btn--hollow-white {
    background-color: transparent;
    color: white;
    border-color: white;

    svg {
        fill: inherit;
        color: inherit;
    }
}



// Btn group
// ----------------------------------------------------------------------------


.btn--group {
    > .btn {
        display: inline-block;
        margin: 0 10px;
    }
}


.btn--group-stack {
    > .btn {
        display: block;
        margin: 50px 0 0;
    }
}


.btn--block {
    display: block;
}

.btn--full {
    width: 100%;
}

.btn--group .btn {
    margin: 50px auto 0;
}


.content-main {
    
    .btn--submit.btn--primary {
        background-color: #008EC5;
        color: #fff;

        &:hover, &:focus {
            background-color: darken(#0d83b0, .2) !important; // important to enforce the hover state in focus too
        }
    }

}