body {
    // padding to account for fixed header
    // padding-top: 4.5rem;
}

.page__header {
    @include fixed($t:0, $z:120);
    width: 100%;
    background-color: $color-white;

    transition: all 0.5s ease;
    animation-duration: .5s;
    animation-fill-mode: both;
    will-change: transform,opacity;

    &.slideDown {
        animation-name: slideDown;
    }

    &.slideUp {
        animation-name: slideUp;
    }
}

.page__header-nav__item {
    padding: $spacing;
    display: inline-block;
}

.page__header-nav--sm {
    display: block;
    @include large {
        display: none;
    }
}

.page__header-nav--lg {
    display: none;
    @include large {
        display: block;
    }
}
