@mixin text-underline-crop($background) {
  text-shadow:  .03em 0 $background,
                -.03em 0 $background,
                0 .03em $background,
                0 -.03em $background,
                .06em 0 $background,
                -.06em 0 $background,
                .09em 0 $background,
                -.09em 0 $background,
                .12em 0 $background,
                -.12em 0 $background,
                .15em 0 $background,
                -.15em 0 $background;
}

@mixin text-underline($color-bg, $color-text) {
  background-image: linear-gradient($color-text, $color-text);
  background-size: 1px 1px;
  background-repeat: repeat-x;
  background-position: 0% 100%;

  &:hover, &:focus {
    background-size: 1.5px 1.5px;
    background-position: 0% 95%;
  }

}

@mixin text-selection($selection) {
  &::selection {
    @include text-underline-crop($selection);

    background: $selection;
  }

  &::-moz-selection {
    @include text-underline-crop($selection);

    background: $selection;
  }
}

@mixin link-underline($background, $text, $selection){
  @include text-underline-crop($background);
  @include text-underline($background, $text);
  @include text-selection($selection);

  color: $text;
  text-decoration: none;

  *,
  *:after,
  &:after,
  *:before,
  &:before {
    text-shadow: none;
  }

  &:visited {
    color: $text;
  }
}

$color-text:        #333332;
$color-background:  #fff;
$color-selection:   #b4d5fe;


// Minimal style to prevent conflicting with other links
// and make it easy to override
a {
    color: inherit;
    transition: $base-transition;
    text-decoration: none;

    &:hover, &:focus {
        text-decoration: underline;
    }

}


// Basic link, has visited states
.link--basic {
    &:visited {
        color: $color-black-a50;

        &:hover, &:focus {
            color: darken($color-black-a50, 50%)
        }
    }
}


// Border styles for links
.link--border {
    border-bottom: 1px solid;
    text-decoration: none;
    border-bottom-color: inherit;
}

.link--back {
  font-weight: $font-weight-bold;
}
