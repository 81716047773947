.list-inline {
    margin-left: 0;
    padding: 0;
    list-style: none;
}

    .list-inline > li, 
    .list-inline__item {
        display: inline-block;

        & + & {
            margin-left: $spacing-half;
        }
    }