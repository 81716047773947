
// Brand colors
$color-penny-blue:          #4BCEF3;
$color-penny-red:           #FF4F5A;
$color-penny-black:         #27323E;

// Action colors
$color-bg-color:            #F4F4F5;
$color-go-green:            #56C97C;
$color-yo-yellow:           #FFB945;
$color-dark-yellow:         #F49C18;
$color-white:               #fff;
$color-grey:                #F7F7F7;
$color-dark-grey:           #979797;
$color-dark-red:            #E6334D;
$color-dark-dark-red:       #B8283D;
$color-super-dark-red:      #921f30;

// Common Colors
$color-black-a70:           rgba($color-penny-black, 0.7);
$color-black-a50:           rgba($color-penny-black, 0.5);
$color-black-a30:           rgba($color-penny-black, 0.3);
$color-black-a20:           #D4D6D8;
$color-black-a10:           #E9EAEB;
$color-white-a70:           rgba(white, 0.7);
$color-white-a50:           rgba(white, 0.5);
$color-white-a30:           rgba(white, 0.3);

// Text colors
$color-text-blue:           #00B7EA;
$color-text-blue-dark:      #005A74;
$color-text-red:            #FF0010;
$color-text-red-dark:       #5A0006;
$color-text-green:          #00B43B;
$color-text-green-dark:     #006C24;
$color-text-yellow:         #EC8500;
$color-text-yellow-dark:    #995600;
$color-text-dark:           #1C1C1C;
$color-button-text-blue:    #008EC5;

// Accessibilty Colors
$color-focus:               #6cc6ee;
$color-select:              #b3d4fc;
$color-error:               #f77;

// Sentiments
$color-error: #c0392b;