// -----------------------------------------------------------------------------
// These styles are for responsive embeds. The classnames are generated by wagtail
// when a user adds an embed via the wysiwyg editor. These are useful in general
// for full width video/iframe/objects.
// -----------------------------------------------------------------------------

.responsive-object,
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    background-color: rgba(255,255,255,0.1);
    clear: both;
}

.responsive-object,
.video-wrapper {
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
