$use-important-on-utils: true;



// @prefix - string - a prefix for a classname usually breakpoint based e.g. lg, md, sm
// @className - string - the css className
// @rules - map a map of rules to apply to the class, e.g. (width: 10px, heightL 10px)
@mixin make-class-from-ruleset($className, $rules, $prefix: '', $symbol: '.', $important: $use-important-on-utils) {
    $important: if($important, !important, '');

    .#{escape-class(#{$prefix}#{$className})} {
        @each $k, $v in $rules {
            #{$k}: #{$v} #{$important};
        }
    }
}

// @set - map of maps - ( eg-className: (width: 10px, heightL 10px),)
@mixin make-util-classes($set, $prefix:'', $important: $use-important-on-utils) {
    @each $className, $rules in $set {
        @include make-class-from-ruleset($className, $rules, $prefix, $important:$important);
    }
}


// @set - map of maps - ( eg-className: (width: 10px, heightL 10px),)
@mixin make-responsive-util-classes($set, $important: $use-important-on-utils) {
    @each $size, $val in $breakpoints {
        @include breakpoint-up($size) {
            @include make-util-classes($set, $prefix: breakpoint-prefix($size), $important:$important);
        }
    }
}

@mixin make-print-util-classes($set, $important: $use-important-on-utils) {
    @include print {
        @include make-util-classes($set, $prefix: 'print:', $important:$important);
    }
}


@mixin make-util-mixin($name, $collection, $classNames) {
    @each $className in $classNames {
        $ruleset: map-get($collection, $className);
        @if $ruleset {
            @each $prop, $val in $ruleset {
                #{$prop}: #{$val};
            }
        } @else {
            @error '[#{$className}] is not available in #{$name} mixin';
        }
    }
}

@mixin render-font-style($fontStyleName) {
    $font-style: map-get-strict($font-styles, $fontStyleName);

    $family: map-get($font-style, family);
    $weight: map-get($font-style, weight);
    $line-height: map-get($font-style, line-height);
    $sizes: map-get($font-style, sizes);
    $utils: map-get($font-style, utils);

    @if(map-get($sizes, default) == null) {
        @error 'Font Style [#{$fontStyleName}] has no default size setting.';
    }

    @if($family) {
        font-family: $family;
    }

    @if($weight) {
        font-weight: $weight;
    }

    @if($line-height) {
        line-height: $line-height;
    } @else {
        line-height: 1.5;
    }

    @if($utils) {
        @include utils($utils...);
    }

    @each $name, $value in $sizes {
        @if($name == 'default') {
            font-size: rem($value);
        } @else {
            @include breakpoint-up($name) {
                font-size: rem($value);
            }
        }
    }
}
