
.list-unstyled {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

    .list-unstyled > li {
        list-style: none;
    }


/**
### Block list
Throws a list into a vertical stack. Handy for things like navigation.
*/
.block-list {
    @extend .list-unstyled;
    > li {
        display: block;
    }
}