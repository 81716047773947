.accordion {
  .accordion-item {
    border-bottom: 1px solid rgba(#000, 0.3);
    color: rgba(#383838, 0.95);
    padding: 20px 40px 20px 0;
    cursor: pointer;
    position: relative;

    .accordion-item--heading {
      display: inline-block;
      font-family: $font-family-primary-sans-serif;
      font-weight: $font-weight-bold;
      color: $color-text-dark;
      font-size: 21px;

      @include narrow {
        font-size: 18px;
      }

      .accordion-item--heading-container {
        color: $color-text-dark;

        &[aria-expanded="true"] {
          margin-bottom: 1rem;

          .icon-accordion-arrow {
            transform: rotate(180deg);
            top: 40px;

            @include narrow {
              top: 27px;
            }
          }
        }

        .icon-accordion-arrow {
          top: 45%;
          margin-top: 0;
          transition: transform .3s ease-in;

          @include narrow {
            top: 42%;
          }
        }
      }
    }
  }
}
