// ----------------------------------------------------------------------------
// Breakpoints and simple media query mixins
// ----------------------------------------------------------------------------

$breakpoint-xxsmall: 341px;
$breakpoint-xsmall: 480px;
$breakpoint-small: 600px;
$breakpoint-medium: $breakpoint-small + 1px;
$breakpoint-medium-plus: 768px;
$breakpoint-medium-plus-plus: 960px;
$breakpoint-large: 1000px;
$breakpoint-xlarge: 1280px;

@mixin xxsmall {
    @media only screen and (max-width: $breakpoint-xxsmall) {
        @content;
    }
}

@mixin xsmall {
    @media only screen and (max-width: $breakpoint-xsmall) {
        @content;
    }
}

@mixin small {
    @media only screen and (min-width: $breakpoint-xsmall) {
        @content;
    }
}

@mixin narrow {
    @media only screen and (max-width: $breakpoint-small) {
        @content;
    }
}

@mixin medium {
    @media only screen and (min-width: $breakpoint-medium) {
        @content;
    }
}

@mixin large {
    @media only screen and (min-width: $breakpoint-large) {
        @content;
    }
}

@mixin xlarge {
    @media only screen and (min-width: $breakpoint-xlarge) {
        @content;
    }
}


/* Breakpoint debug flags for development */
body.develop {
    &:before,
    &:after {
        content: "";
        display: inline-block;
        padding:2px 8px;
        font-size: 0.75em;
        color:#fff;
        position: fixed;
        bottom: 0;
        z-index: 1000;
    }
    &:before {
        right: 0;
        @include xsmall {  content: "xsmall"; background: #d35400; }
        @include small { content: "small"; background: #1abc9c; }
        @include medium { content: "medium"; background: #8e44ad; }
        @include large { content: "large"; background: #c0392b; }
        @include xlarge { content: "xlarge"; background: #2c3e50; }
    }
    &:after  {
        left: 0;
        @include narrow { content: "narrow"; background: #3498db; }
    }
}