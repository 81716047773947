.genesys-chat {
    margin: 3px auto;
    text-align: center;
    display: flex;
    justify-content: center;
}

.cx-widget {
    .cx-title {
        font-family: MullerMedium, Arial, arial, sans-serif;
    }

    .cx-icon {
        margin-top: 6px !important;
    }

    .cx-control-label {
        margin-top: 30px;
    }

    .cx-titlebar {
        padding-bottom: 4px !important;
    }

    .cx-form-inputs {
        th {
            border: none;
            font-family: MullerLight, Arial, arial, sans-serif;
        }

        label {
            display: block;
            margin-top: 9px;
        }
    }

    .cx-widget.cx-side-button-group {
        transform-origin: top right;
        -ms-transform-origin: top right;
        transform: rotate(-90deg) translate(100%, -100%);
        top: 100%;
        right: 0;
        z-index: 9999 !important;
    }

    // if not hidden this adds empty space at the bottom of the page
    .cx-widget-status {
        display: none;
    }

    .cx-input {
        outline-style: none !important;
    }
}
