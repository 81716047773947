


small,
.small {
   font-size: 16px; /* 15px | 13.5px */
   opacity: .9;
   display: block;

    @include small {
        font-size: 14px; /* 15px | 13.5px */
    }

}