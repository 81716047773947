$font-family-base: 'Graphik Web', sans-serif;
$font-family-headings: 'Graphik Web', sans-serif;

$font-weight-hairline: 100;
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-extrabold: 800;
$font-weight-black: 900;

$font-weight-base: $font-weight-normal;


// Use this config to set font family, font weight, and sizes for each breakpoint.
//   <style-name>:(
//    family: <valid css font stack>, default: inherits font-weight base
//    weight: <valid css font-weight>, default: $font-weight-normal,
//    line-height: <unitless line-height>, // default: $line-height-default
//    utils: (capitalize) // list of util classes for styles to apply
//    sizes: ( // sizes must be specified in px. they are converted to rems
//      default: 14px, // default is required
//      md: 16px, // px size for md breakpoint and up
//      lg: 17px, // px size for lg breakpoint and up
//    )
//  ),


/*
* NOTE
*  This font config needs to be rendered manually using the "render-font-style" @mixin
*  i.e. .thing { @include render-font-style(default) }
**/

$font-styles: (
  default:(
    family: $font-family-base,
    weight: $font-weight-normal,
    line-height: 1.5,
    sizes: (
      default: 14px,
      md: 16px,
      lg: 17px,
    )
  ),

  large: (
    sizes: (
      default: 18px,
      md: 20px,
      lg: 22px,
    )
  ),

  small: (
    sizes: (
      default: 12px,
      md: 14px,
      lg: 14px,
    )
  ),

  h1: (
    family: $font-family-headings,
    weight: $font-weight-bold,
    sizes: (
      default: 30px,
      md: 50px,
      lg: 70px,
    )
  ),

  h2: (
    family: $font-family-headings,
    weight: $font-weight-bold,
    sizes: (
      default: 20px,
      md: 28px,
      //lg: 42px,
    )
  ),

  h3: (
    family: $font-family-headings,
    weight: $font-weight-bold,
    sizes: (
      default: 16px,
      md: 21px,
      lg: 22px,
    )
  ),

  h4: (
    family: $font-family-headings,
    weight: $font-weight-bold,
    line-height: 1.5,
    sizes: (
      default: 14px,
      md: 16px,
      lg: 17px,
    )
  ),

  h5: (
    family: $font-family-headings,
    weight: $font-weight-bold,
    utils: (uppercase, tracking-2),
    sizes: (
      default: 11px,
      md: 12px,
      lg: 13px,
    )
  ),


  h6: (
    family: $font-family-headings,
    weight: $font-weight-bold,
    utils: (uppercase, tracking-2),
    sizes: (
      default: 10px,
      md: 11px,
      lg: 12px,
    )
  ),
);
