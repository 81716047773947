$glossary-easing-out: cubic-bezier(0.4, 0, 1, 1);
$glossary-link-colour: #117ea5;

// Class to define the plain state before any trigger is added. Untouched.
.glossary-tooltip {
  background-color: transparent;
  color: #0b0c0c;
  position: relative;
  border: 2px solid $glossary-link-colour;
  padding: 1px 10px;
  border-radius: 999px;
  cursor: pointer;

  // focus and hover styles the button link more like an anchor tag
  &:focus {
      color: white;
      background-color: $glossary-link-colour;
      text-decoration: none;
      border-bottom-color: #0b0c0c;
    }
    &:focus &:active {
        outline: none;
        color: $glossary-link-colour !important; // default color of links as set just above
        background-color: unset;
        box-shadow: none;
        text-decoration: none;
    }

  .glossary-dialog {
    color: #0b0c0c !important;
    position: absolute;
    background-color: white;
    min-width: 300px;
    max-width: 90vw;
    box-shadow: 0 5px 24px -6px rgba(0,0,0,.35);
    border-radius: 12px;
    z-index: 999;

    .glossary-dialog--inner {
      padding: 12px 32px 32px;

      .glossary-dialog--actions {
        display: flex;

        .glossary-dialog--dismiss {
          margin-left: auto;
          background-color: transparent;
          cursor: pointer;
          &:hover, &:active {
            svg path {
              fill: black;
            }
          }
          &:focus {
            outline: 2px solid $glossary-link-colour;
          }

          svg {
            width: 30px;
            height: 30px;
            margin-top: 2px;
          }
        }
      }
      .glossary-dialog--content {
        text-align: left;
        .glossary-dialog--content-definition {
          // height is targeted to cut text off to show scroll is an option if browser has hidden the scrollbars
          max-height: 291px;
          overflow-y: auto;
        }
      }
    }
    &.glossary-dialog--closing {
      transition:
        transform 0.1s $glossary-easing-out,
        opacity 0.2s $glossary-easing-out;
      visibility: visible;
      transform: scale(0.3);
      opacity: 0;
    }

    .glossary-dialog--tail {
      display: block;
      position: absolute;
      width: 17px;
      height: 8px;
      font-size: 0;
      pointer-events: none;
      background: no-repeat 0 0 url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='8'%3E%3Cpath fill='%23fff' d='M9.87 6.71a2 2 0 01-2.74 0L0 0h17L9.87 6.71z' /%3E%3C/svg%3E");
    }

    &.glossary-dialog--above {
      .glossary-dialog--tail {
          bottom: 0;
          transform: translate(-50%, 100%);
      }
    }
    &.glossary-dialog--below {
      .glossary-dialog--tail {
          top: -16px;
          transform: translate(-50%, 100%) rotate(180deg);
      }
    }
  }
}
