html {
    -webkit-tap-highlight-color: transparent;
    color: #1C1C1C;
    font-family: $font-family-primary-sans-serif;
    font-size: 20px;
    height: 100%;
    line-height: 1.5;
    min-height: 100%;

    // @include medium {
    //     background-color: $color-bg-color;
    // }

    @include narrow {
        font-size: 18px;
    }

}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smooth: always;
    height: 100%;
    position: relative;
}

.small {
    font-size: 13px;
}

.container {
    margin: 0 auto;
    width: 100%;
    max-width: 64rem;
    padding: 0 $spacing;
}
