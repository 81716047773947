
h1, h2, h3, h4
.h1, .h2, .h3, .h4 {
    // margin: 1.414em 0 0.5em;
    font-weight: inherit;
    line-height: 1.1;
    margin-bottom: 0;


    &:first-child {
        margin-top: 0;
    }

}

h1,
.h1 {
    font-size: 36px;
    font-weight: 800;
    font-style: normal;

    &.h1--rich-text-label {
        * {
            font-size: 36px;

            @include medium {
                font-size: 40px;
            }

        }
    }

    @include medium {
        font-size: 40px;
    }

    @include xlarge {
        font-size: 50px;

        &.large {
            font-size: 60px;
        }
    }
}

h2,
.h2 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;

    @include medium {
        font-size: 28px;
    }

}

h3,
.h3 {
    font-size: 20px;
    font-family: Georgia, Times, "Times New Roman", serif;
    line-height: 1.4;

    @include medium {
        font-size: 27px;
    }

}

.h3--feature {
    font-family: $font-family-primary-sans-serif;
    font-weight: $font-weight-bold;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    padding-bottom: 15px;
}


h4,
.h4 {
    font-size: 19px;
    font-weight: 700;
    line-height: 1.4;

    @include medium {
        font-size: 21px;
        &.h4--richtext-label {
            p {
               font-size: 21px;
            }
        }
    }



}

h5,
.h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: rgba(#1C1C1C, .5);
}

p, li {
    font-size: 17px;

    @include medium {
        font-size: 19px;
    }

}

.u-border-bottom {
    border-bottom: 1px solid rgba(255,255,255,0.3);
    padding-bottom: 15px;
}

.u-border-top {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    padding-top: 15px;
}