
.u-hidden {
    display: none;
}

.u-hidden-small {
    @include narrow {
        display: none;
    }
}

.u-hidden-medium {
    @include medium {
        display: none;
    }
}

.u-hidden-large {
    @include large {
        display: none;
    }
}


// Hidding elements on Touch devices
.touch .u-hidden-touch {
    display: none;
    visibility: hidden;
}

// Hidding elements on Non Touch screens
.no-touch .u-hidden-no-touch {
    display: none;
    visibility: hidden;
}


