$breakpoints: (
    // first item must be zero px
    'default':  0px,
    'xs':  $breakpoint-xsmall,
    'sm':  $breakpoint-small,
    'md':  $breakpoint-medium,
    'mp':  $breakpoint-medium-plus,
    'mpp':  $breakpoint-medium-plus-plus,
    'lg':  $breakpoint-large,
    'xl':  $breakpoint-xlarge
);


// Minimum breakpoint width. Null for the smallest (first) breakpoint.
@function breakpoint-min($name, $breakpoints: $breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

// Maximum breakpoint width. Null for the largest (last) breakpoint.
// The maximum value is calculated as the minimum of the next one less 0.02px
@function breakpoint-max($name, $breakpoints: $breakpoints) {
    $next: breakpoint-next($name, $breakpoints);
    @return if($next, breakpoint-min($next, $breakpoints) - 0.02, null);
}



// Name of the next breakpoint, or null for the last breakpoint.
@function breakpoint-next($name, $breakpoints: $breakpoints, $breakpoint-names: map-keys($breakpoints)) {
    $n: index($breakpoint-names, $name);
    @if not $n {
        @error 'breakpoint `#{$name}` not found in `#{$breakpoints}`';
    }
    @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}


// Breakpoint viewport sizes and media queries.
// Breakpoints are defined as a map of (name: minimum width), order from small to large:

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// Useful for making responsive utilities.
@function breakpoint-prefix($name, $breakpoints: $breakpoints) {
    @return if(breakpoint-min($name, $breakpoints) == null, '', '#{$name}:');
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin breakpoint-up($name, $breakpoints: $breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin breakpoint-down($name, $breakpoints: $breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints

@mixin breakpoint-between($lower, $upper, $breakpoints: $breakpoints) {
    $min: breakpoint-min($lower, $breakpoints);
    $max: breakpoint-max($upper, $breakpoints);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include breakpoint-up($lower, $breakpoints) {
            @content;
        }
    } @else if $min == null {
        @include breakpoint-down($upper, $breakpoints) {
            @content;
        }
    }
}



// Media between the breakpoint's minimum and maximum widths.
// No minimum for the smallest breakpoint, and no maximum for the largest one.
// Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.

@mixin breakpoint-only($name, $breakpoints: $breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    $max: breakpoint-max($name, $breakpoints);

    @if $min != null and $max != null {
        @media (min-width: $min) and (max-width: $max) {
            @content;
        }
    } @else if $max == null {
        @include breakpoint-up($name, $breakpoints) {
            @content;
        }
    } @else if $min == null {
        @include breakpoint-down($name, $breakpoints) {
            @content;
        }
    }
}

