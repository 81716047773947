
// -----------------------------------------------------------------------------
// Octave SCSS Base
// -----------------------------------------------------------------------------
// 1. Settings - Variables for colors, typography and basic spacing
// 2. Tools - Media queries for breakpoints, helpers and functions
//

$color-light-brown: #625a5e;
$color-dark-brown: #51494e;
$color-yellow: #fed330;
$color-footer-grey: #dee1e0;
$text-color: #1c1c1c;

// 2018 Colors
$color-pink: #D12B43;
$color-blue: #158FBE;
$color-orange: #F49C18;
$color-purple: #BD246E;


@import 'settings/index'; // Start here, add font sizes,  base
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'utilities/index';
@import 'themes/index';


// new framework
@import '1_framework/index';
@import '1_framework/public.tools';
@import '1_framework/public.general';
@import '1_framework/public.utils';


a {
    color: #117EA5;
    &:focus {
        @include a-focus-state;
    }
    &:focus &:active {
        // Do nothing when just navigating, make
        outline: none;
        color: #117EA5 !important; // default color of links as set just above
        background-color: unset;
        box-shadow: none;
        text-decoration: none;
        border: none;
    }
}

.theme-pink {
   background-color: $color-pink;
   color: white;

    h1 {
        color: white;
    }

    h3, h2  {
        color: white;
    }

    &.white-links {
        a {
            @include link-underline($color-pink, white, white);
             &:focus {
                 @include a-focus-state;
                 text-shadow: none;
                 background-image: none;
             }
        }
    }


    //
    //a:not(.btn) {
    //    @include link-underline($color-pink, white, white);
    //}

}

.theme-light-grey {
    background-color: $color-footer-grey;

    a:not(.link-image) {
        @include link-underline($color-footer-grey, $text-color, $color-footer-grey);
    }

}

.white-links {
    a {
        @include link-underline($color-pink, white, white);
        cursor: pointer;

        &:focus {
            @include a-focus-state;
        }
    }
}


$color-text:        #333332;
$color-background:  #fff;
$color-selection:   #b4d5fe;

.theme-offwhite {
    background-color: #F8F9F9;
}

.spacing--small {
    padding-top: 50px;
    padding-bottom: 50px;
}

.govt-link {
    position: relative;
    color: #333;
    padding: 45px 10px 10px !important;
    &:focus {
        color: #0b0c0c;
    }
}

.logo-fees-free-wrapper {
    @include narrow {
        text-align: center;
        display: block;
    }

    &:hover {
        text-decoration: none;
    }
    &:focus {
        padding: 25px 4px !important;
    }
}

.logo-fees-free {
    width: 105px;
    height: 51px;
}

.global-header {
    padding-top: 40px;
    padding-bottom: 40px;

    @include narrow {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    a {
        color: white;
    }

}

.global-nav__link {
    color: white;
    font-weight: 700;
    margin-right: 20px;
}

.global-nav__item a {
    transition: $base-transition;
    text-decoration: none;

    //&:hover, &:focus {
    //    opacity: 1;
    //    border-bottom: white 1.5px solid;
    //}

}

.global-nav__item--active {
    .global-nav__link {
        border-bottom: white 1.5px solid;
    }
}

.global-nav {
    margin-top: 20px;

    @include xlarge {
        margin-top: 0;
        margin-left: 20px;
        display: inline-block;
    }
}


.global-header--wrapper {
    // replicates behaviour of 'wrapper wrapper--medium'
    max-width: 960px;
    margin: 0 auto;

    @include breakpoint-up(xl) {
        // replicates behaviour of 'wrapper wrapper--large'
        max-width: 1280px;
    }
}

.global-header-container {
    position: relative;

    a:focus {
       @include a-focus-state;
    }
}

.logo-tec-wrapper {

    .logo-tec--primary {
        margin-top: -1px;
    }

    display: none;

    position: absolute;
    top: 0;
    right: 0;

    @include breakpoint-up('sm') {
        display: block;
    }

    @include breakpoint-up(xl) {
        display: inline;
        position: relative;
        top: 0;
        right: 0;
    }
}



.global-footer {
    padding-top: 50px;
    padding-bottom: 0;

    li {
        font-size: 14px;
    }

    @include narrow {
        text-align: center;
        padding-top: 30px;
    }
    .u-text-right {
        @include narrow {
            text-align: center!important;
        }
    }
    a:focus {
        @include a-focus-state;
        text-shadow: none;
        background-image: none;
    }
}

.banner--homepage {

    @include narrow {
        padding-top: 50px;
        padding-bottom: 50px;
    }


    @include medium {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    @include large {
        padding-top: 150px;
        padding-bottom: 150px;
    }

}

.content-main {
    padding-top: 75px;
    padding-bottom: 100px;


    @include narrow {
        padding-top: 30px;
        padding-bottom: 50px;
    }

}

.banner--spacing-medium {
    padding-top: 90px;
    padding-bottom: 90px;

    @include narrow {
        padding-top: 45px;
        padding-bottom: 45px;
    }
}

@mixin svg-bullet() {
  //  still no love for multiline so thing is longer than we like but we have to live with it
  background-image: url('data:image/svg+xml;utf8, <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><rect fill="#51494E" width="38" height="38" rx="19"/><path d="M17.24264 21.1924l-2.82843-2.82844L13 19.77816l3.18198 3.182 1.06066 1.06066 7.77818-7.77818-1.41422-1.4142-6.36396 6.36395z" fill="#FFF"/></g></svg>');
}

.detail-list {
    margin-left: 0;
    list-style: none;
    // list-style-image:

    > li {
        margin-top: 4px;
        margin-bottom: 4px;
        padding-left: 10px;
    }

    li:before {
      content:"";
      height: 38px;
      width: 38px;
      display: block;
      float: left;
      margin-left: -0.25em;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      position: relative;
      top: -3px;
      margin-right: 15px;
    }

    li:before {
      @include svg-bullet();
      background-size:90%;
      background-position:center;
      transition: background-size 0.3s;
      -webkit-transition: background-size 0.3s;
    }

}

.eligibility-form {

    fieldset {
        display: inline-block;
        vertical-align: bottom;
    }

    .full + input[type='text'] {
        margin-top: 10px;
    }

    input[type='text'] {
        background-color: white;
        padding: 12px;
        max-width: 330px;
        // width: 60%;
        font-family: $font-family-primary-sans-serif;
        font-size: 40px;
        font-weight: 700;
        height: 70px;
        color: #575757;

        @include narrow {
            height: 55px;
            font-size: 30px;
        }
        &:focus {
            @include input-focus-state;
        }

    }

    @include narrow {
        input[type='text']::placeholder {
            font-size: 24px !important;
        }
    }

    .btn--submit {
        height: 70px;
        border: none;
        background-color: $color-orange;
        color: white;
        margin-left: 10px;

        @include narrow {
            height: 55px;
        }

        &:hover {
            background-color: #e28a06;
        }
    }

}

.btn--submit {
    background-color: $color-orange;
    height: 70px;
    color: white;

    &:hover {
        background-color: rgba($color-orange, .6);
        border-radius: 4px;
    }

    input[type='text']::placeholder {
        font-size: 32px;
    }



}

.btn--submit {
    background-color: $color-dark-yellow;
    border-radius: 4px;
    height: 70px;
    color: $color-white;


    .icon-arrow {
        top: 2px;
        position: relative;

        @include narrow {
            //top: -2px;
            left: -5px;
        }

    }

    &.btn--primary {
        background-color: $color-white;
        color: $color-button-text-blue;

        &:hover {
            background-color: #dee1e0;
        }

        @include narrow {
            font-size: 17px;
        }

        &:focus {
            @include btn-focus-state;
            background-color: #dee1e0 !important;
        }

    }
}

.eligibility-form .btn--submit .icon-arrow {
    @include narrow {
        top: -2px;
    }
}




.label {
    // text-transform: uppercase;
    // font-size: 13px;
    // font-weight: 500;
    .fieldset-wrapper & {
        font-size: 15px;
    }
}

.input {
    background: #FFFFFF;
    border: 1px solid #DEE1E0;
    padding: 15px;
    width: 100%;
    // box-shadow: inset 0 0 3px 0 rgba(0,0,0,0.15);

    &.input-inline {
        width: auto;

        @include xsmall {
            max-width: 75%;
        }

        @include medium {
            max-width: 72%;
        }

        @include large {
            max-width: none;
        }
    }
}

.input:not(select), textarea {
    &:focus {
        @include input-focus-state;
    }
}

.input--required {
    font-size: 17px;
}

.full + input[type='text'],
.full + input[type='email'] {
    margin-top: 5px;
}

.label-helper {
    margin-top: 5px;
}

.fieldset--error {
    .select-style,
    input[type='text'],
    input[type='email'] {
        border-color: #eb6a6f !important;
        border-width: 2px !important;
    }

}

form {
    .full + .full {
        margin-top: 25px;
    }

    .full + .one-half {
        margin-top: 25px;
    }

    .one-half + .full {
        margin-top: 25px;
    }


}

.logo {
    svg {
        @include narrow {
            width: 164px;
            height: 42px;
        }
        width: 219px;
        height: 56px;
    }
}






.alert {

}

.alert--grey label {
    margin-top: 15px;
    text-transform: initial;
    font-weight: normal;

}

.alert--grey {
    padding: 24px 20px 22px;
    background: rgba(#DEE1E0, 0.5);

    label {
        font-size: 20px;
    }
}

ol {
    .alert--grey {
        margin-left: -1.5em;
    }
}

.label-checkbox {
    font-size: 20px;
}


.label-checkbox {
    text-transform: initial;
    font-weight: 500;
}




// -----------------------------------------------------------------------------
// Select
// -----------------------------------------------------------------------------

.select-style {
    display: block;
    width: 100%;
    height: 3em;
    padding: 16px 30px 16px 10px;
    border: 0;
    border-radius: 0;
    font-weight: 400;
    line-height: 1;
    color: #212121;
    outline: 0;
    appearance: none;
    transition: all 0.2s ease-in-out;
    border: 1px solid #ddd;
    position: relative;
    border-radius: 4px;

    @include narrow {
        padding-left: 0;
        padding-right: 0;
        padding-top: 11px;
        font-size: 16px;
    }

    .select-icon {
        position: absolute;
        transform: translate(50%, 50%);
        right: 15px;
        top: 45%;
        pointer-events: none;
    }
    &:focus-within {
        @include select-focus-state;
        padding-top: 12px;
        border-radius: 0;
    }
}

.select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;


    @include narrow {
        width: 90%;
    }
}

.select-style select:focus {
    outline: none;
}




.select-list {

    .select-style {
        font-size: 14px;
        text-transform: uppercase;
        border-bottom: none;
        font-weight: 700;
    }

    select {
        text-transform: uppercase;
        font-weight: 700;
    }

}




// -----------------------------------------------------------------------------
// spacing
// -----------------------------------------------------------------------------

.wrapper {

    h1 + * {
        margin-top: 15px;
    }

    h2 + p {
        margin-top: 20px;
    }

    h2 + p.small {
        margin-top: 5px;
    }

    h4 + p {
        margin-top: 15px;
    }

    p + h2,
    p + h3,
    p + h4 {
        margin-top: 50px;
    }

    ol + *,
    ul + *,
    p + * {
        margin-top: 50px;
    }

    h1 + *,
    h2 + *,
    h3 + *,
    h4 + *,
    h5 + *,
    p + * {
        margin-top: 15px;
    }

    h4 + .small {
        margin-top: 5px;
    }

    p + p {
        margin-top: 15px;
    }

    p + h4 {
        margin-top: 50px;
    }

    p + ul {
        margin-top: 15px;

        + * {
            margin-top: 40px;
        }
    }

    form + p {
        margin-top: 30px;
    }

    h5 + h4 {
        margin-top: 20px;
    }

    form {
        margin-top: 0;
    }

    .fieldset + .fieldset {
        margin-top: 20px;
    }


    table {
        margin-top: 25px;
    }

    .rich-text {
        margin-top: 25px;

        &:first-child {
            margin-top: 0;
        }

    }

    .block-text_block + .block-text_block {
        margin-top: 50px;
    }

}



.theme-pink {
    // > *,
    // > * > * {
    //     margin-top: 25px;
    // }

    // form + form {
    //     margin-top: 40px;
    // }

    h2 + form {
        margin-top: 40px;
    }

    .btn + p {
        margin-top: 15px;
    }

    &:first-child {
        margin-top: 0;
    }

    ul + form {
        margin-top: 40px;
    }

}









.submit-link {
    @include narrow {
        height: auto;
        text-align: left;
        position: relative;
    }

    span {
        @include narrow {
            display: block!important;
            // width: 70%;
            height: inherit;
            white-space: normal;
            text-align: left;
        }
    }

    .icon-arrow {
        @include narrow {
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }

}





// Radio
input[type=radio] {
    width: 0;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    position:relative;
    &:checked + label:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 6px;
        background: $color-blue;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 1rem;
    }

    &:checked + label.radio--checkbox:after {
        border-radius: 0;
        background: none;
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='11' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cdefs%3e%3cpath id='a' d='M14.636 4.293l1.415 1.414-9.037 9.036-4.721-4.721 1.414-1.414 3.307 3.307z'/%3e%3c/defs%3e%3cuse fill='%23000' fill-rule='nonzero' xlink:href='%23a' transform='translate(-2 -4)'/%3e%3c/svg%3e");
        color: #000;
        top: 0;
        left: 12px;
    }
}

input[type=radio]:checked~.radio:before {
    background-color: white;
}

input[type=radio]:checked~.radio.radio--checkbox:before {
    background-color: $color-white;
    box-shadow: 0 0 0 2px rgba(28, 28, 28, 1);
    border: 1px solid $color-text-dark;
}

input[type=radio]:focus {
    @include select-focus-state;
}

.radio {
    cursor: pointer;
    position: relative;
    padding-left: 2.5rem;
    font-size: 19px;
    float: left;
    line-height: 1.4rem;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        width: 1.4rem;
        height: 1.4rem;
        border-radius: 1rem;
        box-shadow: 0 0 0 5px rgba(238, 240, 239, 1);
        transition: background .15s;
        border: 1px solid #DEE1E0;

    }

    &.radio--checkbox {
        &:before {
            border-radius: 4px;
            left: 5px;
            box-shadow: 0 0 0 2px rgba(238, 240, 239, 1);
        }
    }
}

.radio-checkbox-container {
    display: inline-block;
    background: $color-dark-dark-red;
    padding: 16px;
    margin-right: 16px;
    border-radius: 4px;

    &.checked {
        background: $color-white;
        color: $color-text-dark;
    }
}

// -----------------------------------------------------------------------------
// checkbox with label
// -----------------------------------------------------------------------------

.checkbox {
    position: absolute; // take it out of document flow
     opacity: 0; // hide it

    & + label,
    .checkbox-label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    // Box.
    & + label:before,
    .checkbox-label:before {
        content: '';
        margin-right: 26px;
        display: inline-block;
        vertical-align: text-top;
        width: 26px;
        height: 26px;
        background: white;
        border-radius: 4px;
        transition: $base-transition;
        border: 1px solid #DEE1E0;
        box-shadow: 0 0 0 5px rgba(238, 240, 239, 1);
        margin-left: -65px;
    }

    // Box hover
    &:hover + label:before {
        background: $color-blue;
        border-color: $color-blue;
    }

    // Box focus
    &:focus + label:before {
        box-shadow: 0 0 0 5px rgba(238, 240, 239, .5);
    }

    // Box checked
    &:checked + label:before {
        background: $color-blue;
        border-color: $color-blue;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
        content: '';
        position: absolute;
        left: -57px;
        top: 12px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow:
          2px 0 0 white,
          4px 0 0 white,
          4px -2px 0 white,
          4px -4px 0 white,
          4px -6px 0 white,
          4px -8px 0 white;
        transform: rotate(45deg);
    }

}


.homepage-form {
    position: relative;

    form {
        display: flex;
        align-items: top;

        input {
            margin-top: 0!important;
        }
    }

    @include narrow {

        span {
            display: none;
        }

    }

}


.notification {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 90px;
    padding-right: 24px;

    h5 + p {
        margin-top: 5px;
    }

}

.notification + * {
    margin-top: 20px;
}


.notification--bad {
    background: rgba(#F37B70, 0.3);
}

.icon-notification {
    position: absolute;
    left: 24px;
    top: 24px;
}



ol ol {
    margin-top: 15px;
}

ol + .btn,
ol + form {
    margin-top: 30px;
}

.logo-fees {
    margin-left: -25px;
    width: 180px;
    height: 61px;

    @include narrow {
        width: 180px;
        height: 61px;
    }
}


.logo svg {
    @include narrow {
        width: 120px;
        height: 31px;
    }
}

footer {
    @include narrow {
        .list-inline {
            li {
                display: block;
            }
        }
    }
}

.padding-left {
    padding-left: 50px;
    @include narrow {
        // max-width: 70%;
    }
}


select::-ms-expand {
    display: none;
}

.accordion-content {
    padding-bottom: 25px;
}

.accordion-trigger {
    border-bottom: 1px solid rgba(#000, 0.3);
    color: rgba(#383838, 0.95);
    padding: 20px 40px 20px 0;
    cursor: pointer;
    position: relative;

    h4 {
        font-size: 16px;
    }
}


.accordion-content + h3 {
    margin-top: 75px;
}

.popout-content {
    background: $color-white;
    color: $color-text-dark !important;
    border-radius: 5px;
    padding: 22px;
    float: left;
    max-width: 550px;
    position: relative;

    &.popout-content--error {
        padding-top: 8px;
        padding-bottom: 8px;
        float: none;
        margin-top: 17px;
        position:relative;
        padding-left: 42px;

        .icon-popout-error {
            position: absolute;
            left: 15px;
            top:10px;
        }

        .arrow-up {
            left: 6px;
        }
        .rich-text {
            margin-top: 0;
        }
    }
}



.arrow-up {
    @include arrow(up, #fff, .5em);
    position: absolute;
    top: 0;
}

.action-link {
    color: $color-white;
    background: $color-dark-dark-red;
    float: left;
    padding-top: 6px;
    padding-right: 22px;
    padding-bottom: 6px;
    padding-left: 22px;
    margin-right: 8px;
    border-radius: 4px;
    font-family: $font-family-primary-sans-serif;
    font-size: 14px;
    border-bottom: none;
    margin-bottom: 15px;
    cursor: pointer;

    &:focus {
        @include btn-focus-state;
    }

    &:hover, &:focus {
        color: $color-white;
        opacity: 1;
        background-color: $color-super-dark-red;
    }

    .icon-accordion-arrow {
        position: relative;
        margin-left: 4px;
    }

}


.icon-accordion-arrow {
    position: absolute;
    right: 0;
    top: 50%;
}

.large-letter {
   font-weight: 500;
    display:inline-block;
    min-width: 22px;
}

p + ul.error {
    margin-top: 5px;
    li {
        padding: 5px 0;
        @include breakpoint-down('sm') {
            margin-left: -74px;
        }
    }
}

.country-select-flag {
    display: none !important;
    margin: 0 !important;
}

.country-code {
    display: inline-block;
    background-color: #DEE1E0;
    padding: 15px;
    border: 1px solid #DEE1E0;
    line-height: 1.15;
    vertical-align: top;
}

.document-download {
    padding: 23px 23px;
    background-color: $color-grey;
    border: 1px solid $color-dark-grey;
    display: inline-flex;
    align-items: center;

    a {
        margin-left: 12px;
        span {
            font-size: $font-size-xxsmall;
        }
    }
}

.theme-offwhite .document-download {
    border: none;
    background-color: $color-white;
}

.intro-block {
    border-bottom: 1px solid rgba(255,255,255, 0.3);
    padding-bottom: 10px;
}


// -----------------------------------------------------------------------------
// Layout flex
// -----------------------------------------------------------------------------


.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.u-inline-block {
    display: inline-block;
}


// .page-docs {
//     display: flex;
//     justify-content: space-between;

//     @include narrow {
//         display: block;
//     }
// }

// .flex-col {
//     display: flex;
//     flex-direction: column;
// }

// -----------------------------------------------------------------------------
// accordion
// -----------------------------------------------------------------------------


.accordion-trigger__link {
    font-family: $font-family-primary-sans-serif;
    font-weight: $font-weight-bold;
    color: $color-text-dark;
    font-size: 21px;

    @include narrow {
        font-size: 18px;
    }

}


.sidebar {
    .h2 {
        font-size: 18px;
    }

}

#accordian {
    .md-full div:first-child {
        .accordion-trigger {
            padding-top: 0;
            .icon-accordion-arrow {
                top: 25%;
            }
        }
    }

    .md-full.accordion-right-col div:first-child {
        .accordion-trigger {
            padding-top: 20px;
            .icon-accordion-arrow {
                top: 50%;
            }
        }

    }


}

// Prevents mobile menu background scrolling when menu open
.overlay-open {
    overflow:hidden;
    height:100%;
}

.glossary-term {
    margin-top: -1px !important;
    @include narrow {
        margin-top: 1px !important;
    }
}
