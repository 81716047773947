// -----------------------------------------------------------------------------
// Media blocks
// -----------------------------------------------------------------------------
//
// Float an image to the left, with a clearfixed block of content to the right.
//
// <div class='media'>
//     <img src='assets/images/green-bird.jpg' class='media__image'>
//     <div class='media__body'>
//         <p>
//             The text
//         </p>
//     </div>
// </div>


$spacing-media: 62px;

.media,
.media--rev,
.media--middle-align {
    @include clearfix;
}

.media__image {
    float: left;
    margin-right: $spacing-media;
    @include narrow {
        margin-right: 30px;
    }
}

.media__body {
    @include clearfix;
    overflow:hidden;
    _overflow:visible;
    zoom:1;
}


// Swap the image to the right
.media--rev > .media__image {
    float: right;
    margin-right: 0;
    margin-left: $spacing-media;
}

.media--rev > .media__body {
    @include clearfix;
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.media--middle-align {
    display: table;

    > .media__body {
        display: table-cell;
        vertical-align: middle;

        > * {
            margin: 0;
        }
    }
}
