 table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 15px;
}

    th {
        text-align: left;
        border-bottom: 1px solid #ccc;
        padding: 6px 9px;
        margin-bottom: 5px;
        font-size: 15px;
        vertical-align: top;
    }

    td {
        padding: 20px 10px;
        vertical-align: top;
    }

