//////////// Focus state mixins ////////////
// Note the use of !important as this is ally it always needs priority.

// Anchor links
@mixin a-focus-state {
    outline: 3px solid transparent;
    color: #0b0c0c !important;
    background-color: #00A4D6;
    box-shadow: 0 -2px #00A4D6, 0 4px #0b0c0c;
    padding: 4px;
    text-decoration: none;
    border-bottom: none;
}

// Inputs
@mixin input-focus-state {
    outline: 4px solid black !important;
}

// Buttons
@mixin btn-focus-state {
    outline: 5px solid #1c1c1c !important; // outline, not border so it doesn't ruin the layout
    background-color: #E28A06; // same as the hover background-color for the Check button
    border-radius: unset;
}

@mixin select-focus-state {
    border: 5px solid #1c1c1c !important;
}

// Accessibility for radio buttons
.radio-fieldset--focus-border {
    border: 5px solid transparent;
    padding: 10px;
    margin: -10px;
    &:focus-within {
        @include select-focus-state;
    }
}

// sass-lint:disable single-line-per-selector
h1, h2, h3, h4, h5, h6,
ul, ol, dl,
blockquote, p, address,
.block-item,
hr,
table,
fieldset, figure,
pre {
    margin: 0;
}

ul, ol,
dd {
    margin-left: $base-spacing-unit;
}
