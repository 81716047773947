.tab-nav {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  li {
    font-weight: $font-weight-normal;
    margin-right: 20px;

    &.active a {
      font-weight: $font-weight-bold;
    }

    @include narrow {
      margin-right: 0;
      font-size: 15px;
    }
  }
}

.tabs-wrapper {
  @include narrow {
    padding: 0;
  }
}