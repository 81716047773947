
ul, ol {
    padding: 0 0 0 0;

    li {
        padding: 10px 0 10px 0;

        @include narrow {
            padding: 10px 0 10px 0;
        }
    }
}

li > {
    ul,
    ol {
        margin-bottom: 0;
    }
}


// Specific styles for ol
// ----------------------------------------------------------------------------

ol {
    counter-reset:li;

    > li {
        position: relative;
        list-style: none;

        &:before {
            content: counter(li) "";
            counter-increment: li;
            position:absolute;
            top: 10px;
            left: -30px;
            font-weight: 700;

            @include narrow {
                top: 10px;
            }
        }

    }

    ol {
        margin-left: 35px;
    }

    &.red-list {
        > li {
            &:before {
                color: $color-text-red;
            }
        }

    }


    ol {
        width: 85%;
        margin-bottom: 0;

        > li {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 10px;

            // @include narrow {
            //     font-size: 14px;
            //     line-height: 24px;
            // }

            &:first-child {
                margin-top: 0;
                padding-top: 0;
                &:before {
                    top: 0;
                }

            }

            &:before {
                content: counter(li, lower-alpha) ".";
                font-weight: 700;
                font-family: "Roboto", sans-serif;
                font-size: 20px!important;

                @include narrow {
                    font-size: 16px!important;
                }


            }

            li:before {
                content: counter(li, lower-roman) ".";
                font-weight: 700;
            }
        }
    }

}

.nested-ol {

}

.list-terms {
    margin-top: 25px;

    li {
        padding-top: 10px;
        padding-bottom: 10px

    }

    li:before {
        top: 10px;
    }


}

.large-list {

    > li {

        &:before {
            top: 5px;
            left: -25px;
            font-size: 22px;
            font-family: Georgia, Times, "Times New Roman", serif;

            @include medium {
                font-size: 40px;
            }
        }

    }
}




