// Padding all sides
// ----------------------------------------------------------------------------

//.u-padding--large {
//    padding: 5rem;
//}
//
//.u-padding--medium {
//    padding: 3.2rem;
//}

.u-padding--small {
     padding: 2rem;
}

.u-padding--xsmall {
     padding: 1rem;
}

//.u-padding--small {
//     padding: 2rem;
//}
//
//.u-padding--xsmall {
//     padding: 1rem;
//}


// Padding top-only
// ----------------------------------------------------------------------------

//.u-pt--xlarge {
//    padding-top: 7rem;
//}
//
//.u-pt--large {
//     padding-top: 5rem;
//}
//
//.u-pt--medium {
//    padding-top: 3.2rem;
//}
//
//.u-pt--small {
//    padding-top: 2rem;
//}
//
//.u-pt--xsmall {
//    padding-top: 1rem;
//}


// Padding bottom-only
// ----------------------------------------------------------------------------


//.u-pb--xlarge {
//    padding-bottom: 7rem;
//}

.u-pb--large {
    padding-bottom: 5rem;
}
.u-pb--medium {
    padding-bottom: 3.2rem;
}

.u-pb--small {
    padding-bottom: 2rem;
}

.u-pb--xsmall {
    padding-bottom: 1rem;
}

// Padding top and bottom only
// ----------------------------------------------------------------------------

.u-ptb--medium {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
}

// Padding left-only
// ----------------------------------------------------------------------------


.u-pl--xsmall {
    padding-left: 1rem;
}

// Padding right-only
// ----------------------------------------------------------------------------


.u-pr--xsmall {
    padding-right: 1rem;
}

// ... and so on.
