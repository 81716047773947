
form {
    @include placeholder {
        color: $color-black-a50;
        font-weight: 400;
    }
}

input, select {
    border: none;
    padding: 10px 0 15px 0;
    resize: none;
    background: transparent;
    border-radius: 4px;
}
input {
    &:focus {
        @include input-focus-state;
    }
}

fieldset {
    padding: 0;
    border: 0;
}

.fieldset-wrapper {
    padding: 20px;
    border: 1px solid #DEE1E0;
    @include narrow {
        padding: 8px;
    }

    &:focus-within {
        @include input-focus-state;
    }
}
legend {
    font-size: $base-font-size;
    font-weight: $font-weight-bold;
}



select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $color-penny-black!important;
    color: $color-penny-black;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.inline-nested-richtext p {
    display: inline;
}

// .label {
//     display: block;
// }

// input:-webkit-autofill {
//     -webkit-box-shadow: 0 0 0px 1000px white inset;
//     -webkit-text-fill-color: yellow !important; // to change type color
// }

// input:-webkit-autofill {}
// $color-text

// /* Change the white to any color ;) */

