
.u-mb-0 {
    margin-bottom: 0 !important;
}

.u-mt-0 {
    margin-top: 0 !important;
}

%no-top-margin-first {
    &:first-child {
        margin-top: 0!important;
    }
}

%no-bottom-margin-last {
    &:last-child {
        margin-bottom: 0;
    }
}

.u-m-reset {
    margin: 0;
}

.u-mt--large {
    margin-top: 5rem!important;
}

.u-mt--medium {
    margin-top: 55px!important;
}

.u-mt--small {
    margin-top: 50px!important;
}

.u-mt--xsmall {
    margin-top: 25px!important;
}

.u-mt--xxsmall {
    margin-top: 15px!important;
}

.u-mb--large {
    margin-bottom: 5rem;
}

.u-mb--medium {
    margin-bottom: 3.2rem;
}

.u-mb--small {
    margin-bottom: 2rem;
}

.u-mb--xsmall {
    margin-bottom: 1rem;
}

.u-mtb--small {
    margin-top: 50px!important;
    margin-bottom: 50px!important;
}

.u-mtb--xxsmall {
    margin-top: 15px!important;
    margin-bottom: 15px!important;
}

.btn--spacing {
    margin-top: 3!important.2rem;
    margin-bottom: 3.2rem;
}