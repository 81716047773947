
/* * * * * * * * * * * * * * * * * * * * * * * *
* Grid Class output
* * * * * * * * * * * * * * * * * * * * * * */
// NOTE: these are named with flex- to differ from the preexisting grid setup in feesfree
.flex-grid { @include grid; }
.flex-grid--gutters { @include gutters(30px);}
.flex-grid--gutters-sm { @include gutters(12px);}

@include make-responsive-util-classes($grid-column-classes, $important: false);
